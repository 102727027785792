export abstract class EndpointConstant {
    static readonly GET_EQUIPMENTS = 'get-equipments';
    static readonly GET_TRANSFORMED_DATA = 'get-transformeddata';
    static readonly GET_TRANSFORMED_DATA_AGGREGATE = 'get-aggregated-dfft-data';

    static readonly GET_MACHINE_CARD = 'get-machine-card';

    static readonly GET_TRANSFORMED_DATA_FFT = 'get-fftdata';

    static readonly GET_TRANSFORMED_PREV_DATA_FFT = 'get-prev-fft-get';
    static readonly GET_TRANSFORMED_PREV_DATA_DFFT = 'get-prev-dfft-get';

    static readonly GET_TRANSFORMED_DATA_FFT_AGGREGATE = 'get-aggregated-fft-data';

    static readonly GET_MACHINE_AVAILABILITY_STATUS = 'get-hourly-aggregated-data';

    // static readonly GET_AVG_FAULT_FREQUENCY = 'get-avgfaultfrequencies';

    static readonly GET_AVG_FAULT_FREQUENCY = 'get-ffa';
    static readonly GET_IRMS_FREQUENCY = 'get-irmsdata';
    static readonly POST_EQUIPMENT_FAULT_INSTANCES = 'post-equipmentfaultinstances';

    static readonly GET_PLANTS = 'plants';
    static readonly GET_TENANTS = 'tenants';

    // monitor-realtime apis
    static readonly GET_MACHINES_REALTIME_METRICS = 'get-machines-realtime-metrics';
    static readonly GET_ALL_MACHINES_DAYWISE_AGGREGATED_DATA = 'get-all-machines-daywise-aggregated-data';
    static readonly GET_ALL_MACHINES_HOURLY_AGGREGATED_DATA = 'get-all-machines-hourly-aggregated-data';
    static readonly GET_PLANT_LIFE_VALUE = 'get-plant-life-value';
    static readonly GET_HOURLY_PLANT_LIFE_VALUE = 'get-hourly-plant-life-value';
    static readonly GET_HOURLY_MACHINE_LIFE_VALUE = 'get-hourly-machine-life-value';

    static readonly GET_REALTIME_METRICS = 'get-realtime-metrics';
    static readonly GET_DAYWISE_AGGREGATED_dATA = 'get-daywise-aggregated-data';
    static readonly GET_HOURLY_AGGREGATED_dATA = 'get-hourly-aggregated-data';

    static readonly GET_DAYWISE_AGGREGATED_METRICS = 'get-daywise-aggregated-metrics';
    static readonly GET_HOURLY_AGGREGATED_METRICS = 'get-hourly-aggregated-metrics';
    // static readonly GET_INSTANT_AGGREGATED_METRICS = 'get-instant-aggregated-metrics';
    static readonly ALERTS = 'alerts';
    static readonly GET_ALERT_COUNT = 'get-alerts-count';
    static readonly GET_ALERTS_BY_FILTER = 'get-alerts-by-filter';
    static readonly GET_ALL_MACHINES_AGGREGATED_METRICS = 'get-all-machines-aggregated-metrics';
    static readonly GET_SINGLE_MACHINE_REALTIME_METRICS = 'get-single-machine-realtime-metrics';
    static readonly METRICS = 'metrics';
    static readonly GET_MACHINE_INSTANT_RISKSCORE = 'get-machine-instant-riskscore';
    static readonly GET_MACHINE_TELEMETRY = 'get-machine-telemetry';
    static readonly GET_MACHINE_CYCLE_TELEMETRY = 'get-machine-cycle-time';
    static readonly GET_MACHINES_AVG_METRICS = 'get-machines-avg-metrics';
    static readonly GET_PLANT_SHIFT_TIMINGS = 'get-plant-shift-timings';
    static readonly GET_AGGREGATED_FFA_DATA = 'get-aggregated-ffa';
    static readonly GET_STATUS_FOR_TAGGING = 'get-status-for-tagging';
    static readonly GET_TELEMETRY = "get-telemetry";
    static readonly GET_AGGREGATED_CYCLETIME = "get-aggregated-cycletime"
    static readonly GET_AGGREGATED_CYCLETIME_METRICS = "get-aggregated-cycle-time-metrics"

    static readonly POST_TICKET_TAG = 'tickets'
    static readonly GET_ACTIVE_TICKETS = 'tickets'
    static readonly CREATE_NOTIFICATION = 'notifications'
}
