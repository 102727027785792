export abstract class RouteConstant {
  /* Auth Module */
  static readonly AUTH = "/auth";
  static readonly LOGIN = "/auth/login";
  static readonly NONPAYMENTREDIRECT = "/auth/non-payment-redirect";

  /* Console Module */
  static readonly CONSOLE = "/console";

  /* Dashboard Module */
  static readonly DASHBOARD = "/console/machine-insights";

  // // Analytic Module
  // static readonly create = '/console/overview';

  /* Overview */
  static readonly OVERVIEW = "/console/overview";

  /* Monitor Module */
  static readonly MONITOR = "/console/list";

  /* create Module */
  static readonly CREATE_MODULE = "/console/create";

  /* Optimize Module */
  static readonly OPTIMIZE = "/console/optimize";
}
