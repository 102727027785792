import { Component, HostListener } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "minto-app";
  isOnline = navigator.onLine;
  isOnlineImage: boolean = false;

  ngOnInit(): void {
    this.updateOnlineStatus();
  }

  @HostListener("window:online", ["$event"])
  onOnline(event: Event): void {
    this.isOnlineImage = true;
    // Set a flag in localStorage to indicate that the status should be updated after refresh
    localStorage.setItem("updateOnlineStatusAfterRefresh", "true");
    // Refresh the page
    window.location.reload();
  }

  @HostListener("window:offline", ["$event"])
  onOffline(event: Event): void {
    this.updateOnlineStatus();
  }

  private updateOnlineStatus(): void {
    this.isOnline = navigator.onLine;

    // Check if the flag exists in localStorage
    const updateAfterRefresh = localStorage.getItem(
      "updateOnlineStatusAfterRefresh"
    );
    if (updateAfterRefresh === "true") {
      // Clear the flag
      localStorage.removeItem("updateOnlineStatusAfterRefresh");
      // Set the isOnline property to true
      this.isOnline = true;
    }
  }
}
