import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { StorageService } from "../storage.service";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { NavService } from "../../services/nav.service";
import { RouteConstant, StorageConstant } from "../../constants";

const TENANT_ID = "tenant_id";
const PLANT_ID = "plant_id";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router, private navService: NavService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = StorageService.getItem(StorageConstant.ID_TOKEN);

    if (idToken) {
      let contentType = "";
      if (request.headers.has("Content-Type")) {
        contentType = request.headers.get("Content-Type");
      }

      request = request.clone({
        setHeaders: {
          Authorization: idToken,
          "Content-Type": contentType ? contentType : "application/json",
        },
        params: request.params
          .append(TENANT_ID, StorageService.getItem(StorageConstant.TENANT_ID))
          .append(PLANT_ID, StorageService.getItem(StorageConstant.PLANT_ID)),
      });
    }
    //included to remove the details after the token expiry
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if ([400, 401].includes(err.status)) {
              StorageService.removeAll();
              this.navService.navigate(RouteConstant.LOGIN);
              return;
            }
          }
        }
      )
    );
  }
}
