// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUri: "https://ugkluryc86.execute-api.us-east-2.amazonaws.com/prod/v3",
  baseUriNew: "https://ugkluryc86.execute-api.us-east-2.amazonaws.com/prod/v4",

  clientId: "7go5q4sfrlbl4iv12ncspe7tm6",
  poolId: "us-east-2_R2WTXPl3P",
  identityPoolId: "us-east-2:884a0c46-e283-4bf4-b59a-2c91fddcb8b7",
  awsData: {
    dashboardId: "3ee32acd-7cff-4b44-a972-2bb036303327",
    region: "us-east-2",
    roleSessionName: "minto",
    apiGatewayUrl:
      "https://ugkluryc86.execute-api.us-east-2.amazonaws.com/prod/v3/getdashboardembedurl?",
    cognitoAuthenticatedLogins:
      "cognito-idp.us-east-2.amazonaws.com/us-east-2_1bgHctRhe",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
